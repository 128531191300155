var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"d-flex justify-content-end mb-3"},[_c('b-button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.$router.push({ name: 'profile' })}}},[_c('i',{staticClass:"mdi mdi-plus mr-2"}),_vm._v(" Profile ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"p-3 bg-light mb-4 d-flex justify-content-between"},[_c('h5',{staticClass:"font-size-14 mb-0"},[_vm._v("Password & Security")])]),_c('div',{staticClass:"alert alert-info mb-4"},[_c('p',{staticClass:"mb-0"},[_vm._v("Change your account password below.")]),_c('p',[_vm._v(" Password strength is important so please create a hard to guess password. ")]),_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Note:")]),_vm._v(" If you change your password, you will end your current session. ")])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"old-password"}},[_vm._v(" Old Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.oldPassword.$error,
                },attrs:{"id":"old-password","type":"password"},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.oldPassword=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"new-password"}},[_vm._v(" New Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPassword),expression:"newPassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.newPassword.$error,
                },attrs:{"id":"new-password","type":"password"},domProps:{"value":(_vm.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.newPassword=$event.target.value}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"new-password-ii"}},[_vm._v(" New Password (Again) ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.confirmPassword.$error,
                },attrs:{"id":"new-password-ii","type":"password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value}}})]),(!_vm.submitting)?_c('b-button',{staticClass:"btn btn-success btn-block mt-4",attrs:{"type":"submit"}},[_vm._v(" Submit ")]):_c('b-button',{staticClass:"btn btn-success btn-block mt-4",attrs:{"disabled":"","type":"submit"}},[_vm._v(" Submitting... ")])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }